<template>
    <uw-content title="我的主页(销售)" padding="20px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="GetData()">刷新</el-button>
        </template>

        <!-- 回款统计 -->
        <uw-statistic :column="5" gutter="10px" class="w-margin-b-20" @click.native="$router.push('/my/contract-pay-statistics')">
            <uw-statistic-item type="blue"   title="合同总额" :value="pay_sum.pay_sum_a" :row="[1, 3]" />
            <uw-statistic-item type="yellow" title="未回款金额" :value="pay_sum.pay_sum_c" />
            <uw-statistic-item type="green"  title="已回款金额" :value="pay_sum.pay_sum_b" />
            <uw-statistic-item type="green"  title="回款冲销金额" :value="pay_sum.pay_sum_e" />
            <uw-statistic-item type="red"    title="退款金额" :value="pay_sum.pay_sum_d" />
            <uw-statistic-item type="yellow" title="未开票金额" :value="pay_sum.pay_sum_g" />
            <uw-statistic-item type="green"  title="已开票金额" :value="pay_sum.pay_sum_f" />
            <uw-statistic-item type="green"  title="已开票金额（已回款）" :value="pay_sum.pay_sum_h" />
            <uw-statistic-item type="red"    title="已开票金额（未回款）" :value="pay_sum.pay_sum_i" />
        </uw-statistic>

        <!-- 款项统计 -->
        <uw-card title="款项统计" subtitle="款项统计异常请联系管理员修正" show-footer>
            <!-- 回款统计 -->
            <uw-statistic :column="5" gutter="10px" class="w-margin-b-20" @click.native="$router.push('/my/contract-pay-statistics')">
                <uw-statistic-item type="blue"   title="合同总额" :value="pay_sum.pay_sum_a" :row="[1, 3]" />
                <uw-statistic-item type="yellow" title="未回款金额" :value="pay_sum.pay_sum_c" />
                <uw-statistic-item type="green"  title="已回款金额" :value="pay_sum.pay_sum_b" />
                <uw-statistic-item type="green"  title="回款冲销金额" :value="pay_sum.pay_sum_e" />
                <uw-statistic-item type="red"    title="退款金额" :value="pay_sum.pay_sum_d" />
                <uw-statistic-item type="yellow" title="未开票金额" :value="pay_sum.pay_sum_g" />
                <uw-statistic-item type="green"  title="已开票金额" :value="pay_sum.pay_sum_f" />
                <uw-statistic-item type="green"  title="已开票金额（已回款）" :value="pay_sum.pay_sum_h" />
                <uw-statistic-item type="red"    title="已开票金额（未回款）" :value="pay_sum.pay_sum_i" />
            </uw-statistic>
        </uw-card>

    </uw-content>
</template>

<script>
export default {
    data() {
        return {
            pay_sum: {
                pay_sum_a: 0, pay_sum_b: 0, pay_sum_c: 0,
                pay_sum_d: 0, pay_sum_e: 0, pay_sum_f: 0,
                pay_sum_g: 0, pay_sum_h: 0, pay_sum_i: 0,
            }
        }
    },

    mounted () {
        this.GetData()
    },

    methods: {
        GetData() {
            this.$http.post('/9api/my/home-sale').then(rps => {
                this.pay_sum = rps.data.pay_sum
            })
        }
    }
}
</script>